import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Blog.scss';

class Blog extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row className="show-grid text-left">
            <Col xshidden="true" md={1}></Col>
            <Col xs={12} md={10}>
              <Jumbotron>
                <Link to="/favourite-places-to-study"><h2>My Favourite Places to Study</h2></Link>
                <time dateTime="2019-01-12T00:00:00+00:00" className="post-date">January 12, 2019</time>
                <Link to="/favourite-places-to-study"><Image className="thumbnail" src="assets/Blog/PlacesToStudy/1_SJ2.jpg" /></Link>
                <Link to="/favourite-places-to-study">Continue reading: 'My Favourite Places to Study'...</Link>
              </Jumbotron>
            </Col>
            <Col xshidden="true" md={1}></Col>
          </Row>
          <Row className="show-grid text-left">
            <Col xshidden="true" md={1}></Col>
            <Col xs={12} md={10}>
              <Jumbotron>
                <Link to="/first-hackathon"><h2>My First Hackathon</h2></Link>
                <time dateTime="2019-01-03T00:00:00+00:00" className="post-date">January 3, 2019</time>
                <Link to="/first-hackathon"><Image className="thumbnail" src="assets/Blog/FirstHackathon/uottahack2.JPG" /></Link>
                <p>In this post, I reflect on my first hackathon as a UWaterloo CFM student.</p>
                <Link to="/first-hackathon">Continue reading: 'My First Hackathon'...</Link>
              </Jumbotron>
            </Col>
            <Col xshidden="true" md={1}></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Blog;