import React from 'react';

const SVG = ({
  style = {},
  width = '100%',
  className = '',
  height = '6em',
  viewBox = '0 0 235.079 211.571',
}) => 
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#54B948" d="M0 211.571h235.079V0H0v211.571z"/>
    <path fill="#FFF" d="M152.763 166.734H97.38V67.045h27.825v82.074h26.735c18.5 0 26.162-12.812 26.162-45.695 0-33.163-8.768-42.764-27.568-42.764H89.659v106.074H62.064V60.66h-40.31V43.008h137.397c33.205 0 48.945 17.102 48.945 60.128.001 56.627-23.577 63.598-55.333 63.598"/>
  </svg>;

export default SVG;