import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar/CustomNavbar.js';
// import DarkModeSwitch from './components/NightLight/DarkModeSwitch.js';
import Blog from './components/pages/Blog/Blog.js';
import Home from './components/pages/Home/Home.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

// Articles
import PlacesToStudy from './components/pages/Blog/Articles/PlacesToStudy.js';
import FirstHackathon from './components/pages/Blog/Articles/FirstHackathon.js';

class App extends Component {
  // State only contains background colour rn.
  constructor(props) {
    super(props)

    this.handler = this.handler.bind(this);

    this.state = {
      bgColour: 'white',
    };
  };

  // This function is used to toggle the background colour when called.
  handler = () => {
    if (this.state.bgColour === 'white') {
      this.setState({
        bgColour: '#32578f'
      });
      document.querySelector('body').style.backgroundColor = '#32578f';
    } else {
      this.setState({
        bgColour: 'white'
      });
      document.querySelector('body').style.backgroundColor = 'white';
    }
  };

  render() {
    return (
      <Router>
        <div>
          <CustomNavbar handler={this.handler} props={this.handler} />
          {/*<DarkModeSwitch handler={this.handler} />*/}
          <Route exact path="/" component={Home} props={this.handler} />
          <Route path="/index.html" component={Home} />
          <Route path="/index.htm" component={Home} />
          <Route path="/blog" component={Blog} />
          
          {/* Articles */}
          <Route path="/favourite-places-to-study" component={PlacesToStudy} />
          <Route path="/first-hackathon" component={FirstHackathon} />

          <footer style={{'marginTop': '4em', 'color': 'black'}}>Copyright &copy; James Bury 2020.</footer>
        </div>
      </Router>
    );
  }
}

export default App;
