import React, { Component } from 'react';
import { Container, Row, Col, Image, Jumbotron } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PlacesToStudy extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row className="show-grid text-left">
            <Col xsHidden md={1}></Col>
            <Col xs={12} md={10}>
              <Jumbotron>
                <h2>My Favourite Places to Study</h2>
                <time dateTime="2019-01-12T00:00:00+00:00" className="post-date">January 12, 2019</time>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/1_SJ2.jpg" />
                <br />
                <h3>1. St. Jerome's University (SJU)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/2_SJ2.jpg" />
                <p>St. Jerome's University is by far my favourite place to study on campus. A short walk from SLC, the relatively new SJ2 building provides an incredible amount of sunlight to brighten up your mood while studying. The second floor offers several classrooms with full computer access, for when you want to jam out to Spotify on surround sound during a study session.</p>
                <br />
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/3_Funcken.jpg" />
                <p>Considered to be a part of St. Jerome's, The Funcken Cafe, located on the ground floor of SJ1 is the perfect place to grab a coffee or beer before or after class. Students and staff alike journey here to relax, study, and enjoy a beverage during their breaks. The connecting building, SJ1, boasts a quiet gem of a library, which goes unnoticed by most students.</p>
                <br />
                <h3>2. St. Paul's University College (STP)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/4_STP.jpg" />
                <p>St. Paul's University College offers beautiful and spacious study spaces for anyone who walks in. In particular, St. Paul's Greenhouse offers a perfect space with floor-to-ceiling windows for any student who likes the views. Adjacent to the Greenhouse are three closed-door study rooms, each equipped with whiteboards and comfortable seating. If students need to take a break over the span of the day, they can play a nice game of pool or ping-pong in the next room. You can even play a game of indoor soccer if that may interest you more.</p>
                <br />
                <h3>3. Engineering 6 (E6)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/5_E6.jpg" />
                <p>Everyone's heard of E7, and as a result, most of its classrooms and study spaces will likely be occupied by the time you get there. However, students rarely venture across the bridge to E6 where they can study at one of the various window-side desks on the upper floors. Typically, only students who've had classes in this building know about these spaces, and you'd be hard-pressed to find non-engineering students here. Despite this, the seating is comfortable, and outlets are at convenient locations. Along with the sunlight, this makes for a perfect study space in my book.</p>
                <br />
                <h3>4. Mathematics and Computer Building (MC)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/6_MC.jpg" />
                <p>When you imagine the perfect study space, MC might be your image of the exact opposite. These were my original thoughts until this past semester. During exam time, I found myself studying for days in the labs on the third floor of MC. The labs offered huge amounts of desk space, comfy chairs, and an extra monitor to review materials on. For those late nights when its too cold to go home, you can grab a blanket from your free locker and take a nap in the MC Comfy. If the labs aren't your style, you could also work in the CnD in a relatively comfy booth. Alternatively, you could head over to the upper floor classrooms, the fifth floor grad rooms, or the first floor study tables.</p>
                <br />
                <h3>5. Science Teaching Complex (STC)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/7_STC.jpg" />
                <p>My favourite spot to study in the mornings is at the big wooden table in front of the STC Starbucks. I'll have my coffee next to me, while I work on whatever assignment is due that week. The sun is shining brightly behind me, and when I need a break from my work, I can just check out the people waiting in the 20-something person lineup. It also helps that there's an outlet below the table that I could plug into while I'm doing a CS assignment. Another good spot is in one of the basement floor classrooms. Also, during the day, you'll find that most, if not all of the desks are occupied throughout the building.</p>
                <br />
                <h3>6. Engineering 7 (E7)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/8_E7.jpg" />
                <p>Despite often being well-occupied and in some cases very well-heated, E7 is one of the best places on campus to study. The first floor spaces offer ideal conditions for students looking for a more modern approach to the quiet study spaces provided by DP and DC libraries. On upper floors, you'll find large classrooms with just enough space to spread out. You'll also find desks arranged near windows on certain sides of certain floors. These spaces are usually available for anyone who can find them. One of the best spots can be found by exploring the building. Located in a space that is never travelled after 5pm, you can find comfortable seating, perfect lighting, and absolute silence.</p>
                <br />
                <h3>7. Engineering 5 (E5)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/9_E5.jpg" />
                <p>E5 is one of the most recognizable buildings on campus, with most students walking past it on a daily basis. It is home to multiple classrooms, quiet spaces, and locked-door engineering lounges. In the evenings, the classrooms provide an optimal space for students to quietly learn, while also enjoying a great view. One of the classrooms allows students to see a perfect sunset over campus. With regards to engineering lounges, if you have access be to sure to check them out. If you don't have access, ask a friend or just follow one of the many polite engineers willing to hold the door for you.</p>
                <br />
                <h3>8. Environment 2 (EV2)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/11_Env.jpg" />
                <p>EV2 should not be overlooked as one of the best places to study on campus. On the first floor, you can find a two storey green courtyard known only to Environment and Arts students. Like most of the other places on this list, the overhead daylight exposure is an amazing factor to remember.</p>
                <br />
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/12_Env.jpg" />
                <p>Alternatively, students may prefer the basement floor Environment lounge for their studies. Accompanied by a quaint cafe, the Environment Lounge offers students a place to take a break from their work as well as offer a usually quiet place to study. The lounge looks onto a small green space.</p>
                <br />
                <h3>9. Mike and Ophelia Lazaridis Quantum-Nano Centre (QNC)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/13_QNC.jpg" />
                <p>QNC is located in a very central location to the rest of campus. You'll find students from many different programs studying here. Students primarily study on the first two floors, in either a quiet classroom or at one of the tables near the windows. There is also space in the basement to study, and a piano available for anyone to play.</p>
                <br />
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/14_IQC.jpg" />
                <p>Arguably the best spot to study in QNC is in the IQC. If you ever have the opportunity, the second floor of IQC offers several tables, a great view of the SLC expansion and several whiteboards to write on. To get access, you could theoretically follow someone into IQC, and then proceed to walk up the stairs to the second floor.</p>
                <br />
                <h3>10. Hagey Hall (HH)</h3>
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/15_HH.jpg" />
                <p>Hagey Hall is often out of the way for most students, except for those enrolled in Arts or Environment programs. However, the HH Atrium is nevertheless a great place to relax, complete some group work, or study with friends. On the second floor, just above the main entrance, is a quiet space where students can focus on their studies without interruptions. On the third floor, suspended from the roof, is a comfy lounge for students can take a break from their studies.</p>
                <br />
                <Image className="thumbnail" src="assets/Blog/PlacesToStudy/16_SAF.png" />
                <p>Lastly, my favourite spot to study in Hagey Hall is in the SAF Lounge. Significantly occupied by AFM students, the SAF Lounge offers a large space for quiet study with comfortable seating. There's also a small kitchen in the corner, printing services, and free access for any student.</p>
                <br />
                <Link to="/blog">Back to Blog...</Link>
              </Jumbotron>
            </Col>
            <Col xsHidden md={1}></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PlacesToStudy;