import React, { Component } from 'react';
import { Container, Row, Col, Image, Jumbotron } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class HackathonsArticle extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row className="show-grid text-left">
            <Col xsHidden md={1}></Col>
            <Col xs={12} md={10}>
              <Jumbotron>
                <h2>My First Hackathon</h2>
                <time dateTime="2019-01-03T00:00:00+00:00" className="post-date">January 3, 2019</time>
                <Image className="thumbnail" src="assets/Blog/FirstHackathon/uottahack2.JPG" />
                <p>Every year, hundreds of students from various universities across Canada spend time applying to and attending programming competitions known as "hackathons".</p>
                <p>I attended my first hackathon in February 2018 at UOttawa. At the time, I was a first year Computing and Financial Management student at the University of Waterloo. I'd applied to multiple hackathons before this one, but with my lack-luster resume, I had yet to be accepted.</p>
                <p>My acceptance came just over a week before the hackathon, as the organizing team was sending out the final round of acceptances. I remember the exact moment I received my acceptance. I was in my Introduction to Business class and was working on my Computer Science assignment due later that week. It was just like any other class, except my friends and I were more hyped than ever for that upcoming weekend. I quickly registered for the bus from UW to Ottawa and basked in my excitement. Now, I needed to find a team.</p>
                <p>Hitting up the Hackathon's Facebook, I quickly set out to find enthusiastic team members. Within a few days, I'd found my first Hackathon team and we began to chat over Facebook. Proud of my networking skills, I began to relax and prepare for the big weekend.</p>
                <p>What should you bring to the hackathon? Although the hackathon was a two-night experience, I refrained from bringing a sleeping bag. I figured it was too bulky and wouldn't use it anyways. I was right. Despite the decision I made for UOttaHack, I now bring a warm blanket to any hackathons I attend. This way I can comfortably crash on a couch without being cold. Aside from sleeping gear, I brought my usual toiletries, some cologne to freshen up easily, my favourite university/tech branded hoodie, my chargers, laptop, any other hardware I required, and of course, two sets of headphones. Both headphone sets are bluetooth, so when one dies I just use the other set. This lasts me 6 hours x 2.</p>
                <p>The bus arrived for 2:30 AM at the Davis Centre at UW and we drove through the night to UOttawa, where we arrived in the morning. We stopped twice for food and washroom breaks, but I slept through the majority of the ride. For those of you wondering, the bus was coach. I find that the farther away the hackathon, the higher the quality of the mode of transportation. Some hackathons will reimburse plane rides, while others will send school buses to pick up hackers. Most modes of transportation are covered or at least discounted by major hackathons. Alternatively, you could find other ways to finance your transportation costs. UWaterloo's Math Endowment Fund (MEF) and likely other Endowment Funds, will reimburse some or all project costs within reason. You can find out more <strong><a href="https://mef.uwaterloo.ca/" target="_blank" rel="noopener noreferrer">here</a></strong>.</p>
                <p>Once we arrived at UOttawa, we were led out of the buses and into the beautiful Faculty of Social Science Building. At first, the line to get registered was fairly short, since it was early and those getting off the buses weren't quite aware of the next steps. I quickly got in line after being prompted by one of the volunteers floating nearby. Within a few minutes, I had my swag bag, my T-shirt and my hacker badge. Very quickly after getting registered, I discovered breakfast in a room nearby. I got myself a plate and after loading it up, I headed out to the common area to sit with some of my fellow classmates.</p>
                <p>After about an hour of talking and joking around, the volunteers came by prompting us to attend the opening ceremonies in a building across campus. At opening ceremonies, they announced all the sponsored hack categories and the respective prizes for them. Afterwards, we headed back to the Faculty of Social Science Building, and I went on to find my team. Within 10 minutes of walking around, we found each other and began brainstorming ideas for the hackathon. This was the first hackathon for all of us.</p>
                <p>We decided on building a web application for the health category, which allows users to schedule appointments with nearby medical clinics within a set radius of their location. We determined that our language of choice would be Java, since most of the team was familiar with it, not including myself. Our graduate student team member began working on the backend, a term I was unfamiliar with at the time. The other three of us would begin working on the frontend. I was trying to teach myself Java as quickly as possible, so that I could contribute, but since I only knew Racket from school, I had nothing to build on. As the night progressed, I found myself becoming distracted and tired, so I began to heavily drink coffee, Red Bull, and eat Awake Bars. I didn't get any sleep.</p>
                <p>By Saturday at noon, we had gotten some minimal user-authentication working on the web app. We discovered that the backend our team member had delivered was incompatible with the Java application and decided to scrap it. As the day progressed, we took a few breaks to talk with sponsors, collect swag, and get our free MLH T-Shirts. After dinner, we began to doubt the completion of web app. Additionally, we were unfamiliar with Domain.com and were unsure how to load our content online. I realize now that we didn't utilize our resources as well as we could have. We rarely interacted with mentors and weren't very good at searching for the answers we needed online.</p>
                <p>Around 3:00 AM Sunday morning, we decided to give up on the project. Soon after this decision, my teammate and I had a craving to play ping-pong. So, still on a caffeine-high, we grabbed some markers, styrofoam plates, tape, scissors, and an exacto knife to build ping-pong paddles. We soon found an empty table nearby and started to play with the ball I always kept in my backpack. Before long, we had an audience and multiple hackers looking to play. Relocating from the hacking space to the presentation hall, we put three tables together to create a playing surface closer to regulation size. Using several sponsor drink coasters, we created the net separating the two sides. Multiple hackers, in addition to ourselves, played competitively until the tables were needed for presentations later that day.</p>
                <Image className="thumbnail" src="assets/Blog/FirstHackathon/uottahack3.jpg" />
                <p>Realizing that our project didn't work out, we decided to submit an image of the ping-pong paddles for the category labelled "Most Useless Hack." Unfortunately, we didn't win. That went to the team that coded Minesweeper. However, we still had fun presenting the paddles as embedded data collection devices whose sensors collect raw data on table tennis players' techniques, in realtime. The data would then be sold to sports betting companies to increase the accuracy of betting odds calculated by the company. The company could then adjust their profit margin accordingly.</p>
                <Image className="thumbnail" src="assets/Blog/FirstHackathon/uottahack4.jpg" />
                <p>Overall, the experience was eye-opening, and gave me my first real peek at software development outside of class. Even though our team didn't finish our project, I'm still glad we presented. We actually managed to impress a few of the judges with our ideas, and in several cases make them laugh.</p>
                <br />
                <Link to="/blog">Back to Blog...</Link>
              </Jumbotron>
            </Col>
            <Col xsHidden md={1}></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HackathonsArticle;