import React from 'react';

const SVG = ({
  style = {},
  width = '100%',
  className = '',
  height = '20px',
  viewBox = '1700 1000 4300 3000',
}) => 
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <polygon className="fil0" style={{fill:'#373435'}} points="1715,1292 1835,1292 1940,1635 2054,1290 2149,1290 2263,1635 2368,1292 2485,1292 2312,1803 2215,1803 2100,1471 1985,1803 1888,1803 "/>
      <path className="fil0" style={{fill:'#373435'}} d="M2662 1292l103 0 218 512 -117 0 -46 -114 -215 0 -46 114 -114 0 218 -512zm118 299l-67 -165 -67 165 135 0z"/>
      <polygon className="fil0" style={{fill:'#373435'}} points="3105,1398 2950,1398 2950,1295 3371,1295 3371,1398 3216,1398 3216,1803 3105,1803 "/>
      <polygon className="fil0" style={{fill:'#373435'}} points="3456,1295 3839,1295 3839,1395 3567,1395 3567,1498 3806,1498 3806,1597 3567,1597 3567,1704 3843,1704 3843,1803 3456,1803 "/>
      <path className="fil0" style={{fill:'#373435'}} d="M3963 1295l232 0c65,0 115,18 148,52 28,28 44,68 44,116l0 2c0,82 -44,134 -109,157l124 181 -131 0 -109 -162 -88 0 0 162 -112 0 0 -508zm225 247c54,0 86,-29 86,-72l0 -1c0,-48 -33,-73 -88,-73l-111 0 0 146 113 0z"/>
      <polygon className="fil0" style={{fill:'#373435'}} points="4510,1295 4621,1295 4621,1702 4859,1702 4859,1803 4510,1803 "/>
      <path className="fil0" style={{fill:'#373435'}} d="M4876 1551l0 -2c0,-144 114,-263 271,-263 157,0 269,117 269,261l0 1c0,144 -114,263 -271,263 -157,0 -269,-117 -269,-261zm423 0l0 -2c0,-87 -64,-160 -154,-160 -90,0 -152,71 -152,158l0 1c0,87 64,160 154,160 90,0 152,-71 152,-158z"/>
      <path className="fil0" style={{fill:'#373435'}} d="M5464 1551l0 -2c0,-144 114,-263 271,-263 157,0 269,117 269,261l0 1c0,144 -114,263 -271,263 -157,0 -269,-117 -269,-261zm423 0l0 -2c0,-87 -64,-160 -154,-160 -90,0 -152,71 -152,158l0 1c0,87 64,160 154,160 90,0 152,-71 152,-158z"/>
      <path className="fil0" style={{fill:'#373435'}} d="M1998 1038l0 -134 51 0 0 132c0,38 19,58 50,58 31,0 50,-19 50,-56l0 -134 51 0 0 132c0,71 -40,106 -103,106 -63,0 -101,-35 -101,-104z"/>
      <polygon className="fil0" style={{fill:'#373435'}} points="2339,905 2387,905 2496,1049 2496,905 2547,905 2547,1139 2503,1139 2390,990 2390,1139 2339,1139 "/>
      <polygon className="fil0" style={{fill:'#373435'}} points="2693,1139 2745,1139 2745,905 2693,905 "/>
      <polygon className="fil0" style={{fill:'#373435'}} points="2871,905 2928,905 2989,1069 3050,905 3106,905 3011,1140 2966,1140 "/>
      <polygon className="fil0" style={{fill:'#373435'}} points="3230,905 3406,905 3406,950 3281,950 3281,998 3391,998 3391,1044 3281,1044 3281,1093 3408,1093 3408,1139 3230,1139 "/>
      <path className="fil0" style={{fill:'#373435'}} d="M3645 1018c25,0 39,-13 39,-33l0 -1c0,-22 -15,-33 -40,-33l-51 0 0 67 52 0zm-104 -114l107 0c30,0 53,8 68,24 13,13 20,31 20,53l0 1c0,38 -20,62 -50,73l57 84 -60 0 -50 -75 -40 0 0 75 -51 0 0 -234z"/>
      <path className="fil0" style={{fill:'#373435'}} d="M3855 1104l30 -36c21,17 43,28 70,28 21,0 34,-8 34,-22l0 -1c0,-13 -8,-20 -47,-30 -47,-12 -78,-25 -78,-72l0 -1c0,-42 34,-71 82,-71 34,0 63,11 87,30l-27 39c-21,-14 -41,-23 -61,-23 -20,0 -30,9 -30,20l0 1c0,15 10,20 50,31 47,12 74,29 74,70l0 1c0,46 -35,73 -86,73 -35,0 -71,-12 -99,-37z"/>
      <polygon className="fil0" style={{fill:'#373435'}} points="4176,1139 4227,1139 4227,905 4176,905 "/>
      <polygon className="fil0" style={{fill:'#373435'}} points="4428,952 4357,952 4357,905 4551,905 4551,952 4479,952 4479,1139 4428,1139 "/>
      <polygon className="fil0" style={{fill:'#373435'}} points="4744,1046 4654,905 4714,905 4770,998 4827,905 4885,905 4795,1045 4795,1139 4744,1139 "/>
      <path className="fil0" style={{fill:'#373435'}} d="M5373 1022l0 -1c0,-40 -29,-74 -71,-74 -41,0 -70,33 -70,73l0 1c0,40 29,74 71,74 41,0 70,-33 70,-73zm-195 0l0 -1c0,-67 53,-121 125,-121 72,0 124,54 124,120l0 1c0,67 -52,121 -125,121 -72,0 -124,-54 -124,-120z"/>
      <polygon className="fil0" style={{fill:'#373435'}} points="5537,905 5715,905 5715,951 5588,951 5588,1001 5700,1001 5700,1048 5588,1048 5588,1139 5537,1139 "/>
      <path className="fil1" style={{fill:'#FEFEFE'}} d="M3857 3704l-18 -8c-197,-91 -356,-224 -460,-383 -249,-383 -258,-778 -266,-1196 -1,-32 -1,-63 -2,-95l-1 -43 1494 0 -1 43c-1,32 -1,64 -2,96 -9,418 -17,813 -266,1196 -104,159 -263,292 -460,383l-18 8z"/>
      <path className="fil2" style={{fill:'#FFD857'}} d="M3854 2493l-586 461c-94,-291 -91,-596 -99,-907l1366 0c-8,311 -4,615 -99,907l-583 -461z"/>
      <path className="fil2" style={{fill:'#FFD857'}} d="M4328 3202c-14,26 -30,51 -46,77 -104,160 -259,278 -429,357 -171,-79 -325,-197 -430,-357 -16,-25 -45,-75 -45,-75l476 -380 474 378z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3453 2281c-69,-15 -131,-66 -116,-131 0,-1 -3,-17 -9,-19 0,0 -1,-2 -2,-2 -3,-1 -5,-2 -7,-3 -1,-1 -4,-2 -4,-3 -1,3 -1,5 -3,8 -1,1 -2,2 -3,3 -1,1 -1,2 -2,3 -5,5 -2,13 -2,19 0,2 1,3 -1,4 -2,0 0,-1 -3,-2 -1,0 -1,-1 -2,-2 -1,-1 -5,-1 -6,-2 -1,-1 0,-2 -1,-2 -2,-2 -3,-6 -4,-11 0,-1 -1,-5 -1,-6 0,-4 -11,-12 -7,-8 1,1 0,-2 -6,-3 -4,-1 -8,-1 -13,0 -1,0 -2,1 -2,1 -4,3 -8,7 -14,8 1,-1 1,1 2,1 3,4 1,8 2,12 2,4 4,7 8,9 3,2 9,-1 11,4 0,0 5,1 0,6 -9,9 -20,2 -27,19 12,-5 13,13 18,13 22,3 39,7 52,30 8,14 -13,14 5,18 10,2 16,6 21,13 5,7 6,17 11,23 4,5 5,6 10,10 2,2 9,4 12,4 3,1 4,2 7,3 3,1 5,2 8,4 2,2 7,3 8,5 2,4 0,8 2,11 1,1 4,0 5,1 1,0 4,1 5,0 5,-1 6,-1 9,-2 5,-1 5,1 11,1 1,0 2,1 4,1 1,0 4,1 4,0 2,-6 5,-12 9,-19 4,-6 3,-7 8,-13 -1,0 6,-4 5,-5z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3280 2289c20,11 18,38 36,47 32,15 71,26 106,9 -8,24 1,44 -3,47 -11,9 -44,-2 -65,-10 -15,-6 -26,16 -42,14 1,-2 3,-7 2,-11 -3,-8 -5,-10 -18,-9 -8,1 -5,8 -17,14 -5,-3 -16,-6 -17,3 -1,4 -10,-15 -8,-19 8,-15 10,-18 9,-21 -1,-5 -19,-11 -21,-7 -6,10 -22,-31 26,-29 -13,-8 -11,-10 -8,-20 0,-1 -5,-7 -8,-3 -1,-4 1,-8 5,-8 8,-1 16,-2 24,3z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3376 2190c29,7 73,-31 99,13 -19,-7 -74,23 -99,-13z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3621 2255c-1,38 -40,79 -8,116 -13,0 -29,-5 -38,-12 -5,-4 -4,18 -3,23 1,4 -24,-24 -34,-17 -26,19 -38,27 -77,28 -11,0 -17,9 -20,19 -9,-18 -2,-60 3,-78 14,-50 65,-65 105,-90 29,-17 42,-51 40,-84 21,26 32,63 31,95z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3435 2684c5,1 1,5 -1,6 -4,3 -7,6 -9,10 -2,3 -3,6 -3,10 1,3 1,5 2,8 1,2 2,2 3,4 0,0 2,3 2,3 -1,-4 4,-8 6,-7 25,15 27,-15 42,-25 6,-4 21,1 17,14 15,-8 20,-28 31,-41 26,-28 62,-47 97,-62 -12,-13 -31,-24 -40,-39 -5,-7 -10,-22 0,-20 42,11 66,-15 71,-48 63,8 79,-51 88,-96 4,-22 16,-24 3,-61 -5,-16 28,-21 22,-47 -2,-7 -11,-15 -16,-16 5,14 -19,18 -22,24 -4,9 -10,17 -11,26 -20,-28 -4,-62 15,-89 28,-39 86,-18 111,7 0,-12 -9,-28 -17,-36 23,-3 48,11 79,-6 18,-10 36,-18 45,-39 -38,5 -90,-24 -125,-30 -64,-11 -107,33 -130,85 -23,52 -8,91 17,130 6,10 -22,3 -33,9 -11,6 -15,19 -13,31 14,-15 50,-6 53,20 4,34 -30,84 -63,69 -35,-16 -56,-53 -67,-95 -30,26 -86,5 -112,30 -25,24 39,41 63,52 9,4 18,14 17,25 -39,-23 -85,3 -83,44 2,31 39,50 71,53 13,1 21,16 5,26 -28,17 -59,37 -89,5 -5,-5 -23,-2 -27,8 -2,5 -2,12 -2,17 2,-3 4,-7 6,-7 3,0 7,4 6,6 -3,15 -23,5 -29,17 0,0 0,1 -1,1 0,1 -1,2 -1,3 -4,8 -10,13 0,20 0,-1 5,7 5,8 1,-7 8,-7 8,-7l4 1 4 2z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3305 2539c-4,-1 -8,0 -12,2 -3,1 -6,0 -7,3 -1,2 -2,3 -1,5 0,1 1,3 1,3 -5,0 -10,-4 -14,-8 -1,-1 -2,-3 -3,-4 -1,-1 -1,-2 -1,-4 -1,-1 0,-3 0,-4 0,-4 0,-7 1,-11 1,-2 2,-3 3,-4 1,-1 2,-2 3,-3 3,-3 6,-5 9,-6 5,-2 11,-1 16,1 4,2 9,2 13,3 2,1 6,-6 3,-7 -13,-3 -13,-7 -18,-20 -2,-3 -5,0 -7,2 0,-26 30,-27 43,-17 19,15 5,45 28,53 12,4 25,8 37,2 22,-9 0,-32 7,-51 15,-38 47,-40 85,-3 -34,11 -50,57 -32,87 -30,-12 -68,8 -90,22 20,-20 -8,-36 -15,-32l-8 6c-4,5 -4,10 -9,15 -3,3 -4,3 -8,4 -5,1 -8,1 -13,-2 -1,-1 -4,1 -4,1 -2,3 0,4 0,6 0,0 -4,-3 -4,-3 -3,-2 -6,-4 -8,-7 -2,-3 -3,-10 -1,-14 2,-4 6,-5 9,-8 3,-3 -1,-6 -4,-7l1 1z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4041 2202c3,2 0,7 -3,8 -4,1 -9,-1 -12,1 -14,5 -14,41 -12,37 2,-5 13,-2 20,0 11,2 20,-20 27,-20 17,2 34,23 40,37 1,3 -7,3 -8,11 24,-2 44,13 61,27 8,-14 17,-23 26,-33 -36,-35 -106,-45 -95,-108 1,-8 -8,-15 -12,-19 -5,-4 -15,-5 -21,-6 7,14 -5,15 -4,37 0,1 -2,0 -3,0 -4,-2 -8,-4 -12,-7 -4,-2 -7,-2 -11,-1 -2,0 -5,1 -7,3 -3,2 -5,5 -7,9 0,1 -1,1 -1,2 -1,1 -2,3 -2,4 2,0 8,0 9,2 2,3 2,6 4,8 2,3 4,3 8,5 6,2 10,3 16,3l1 1z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4132 2177c22,-2 44,-19 69,10 -5,-2 -10,-3 -13,-1 -27,22 -60,14 -77,-13 7,-1 16,4 21,3z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4228 2257c36,-18 73,-33 88,-78 3,-9 10,13 10,14 19,55 -19,110 -11,166 -13,-8 -27,-13 -41,-14 -50,-1 -62,29 -65,26 -14,-14 -53,-26 -50,-23 -23,-22 44,-79 69,-91z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4129 2624c5,8 -2,-4 4,9 26,-11 54,-24 83,-12 -13,-59 30,-74 34,-83 6,-14 -23,-43 -45,-35 -28,10 -16,45 -21,70 -3,14 -20,24 -34,19 -23,-8 -32,-29 -38,-53 -4,-16 -35,-18 -42,-5 -19,37 11,-4 9,24 0,5 -22,1 -27,18 -3,11 7,19 21,23 2,1 -3,-6 1,-7 2,-1 12,4 11,5 -13,8 -16,27 7,35 0,0 -2,-7 0,-8 18,-14 37,1 37,1l0 0z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3732 2999c22,10 29,-1 58,-5 4,-1 20,10 25,15 -29,5 -64,30 -83,-10z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3721 3022c-1,-1 -3,-2 -5,-3 -2,-1 -4,-2 -6,-2 -1,0 -4,-1 -5,-1 0,1 2,5 1,6 -2,2 -3,2 -5,4 -1,1 -1,2 -2,3 -1,3 -2,5 -2,8 0,1 1,5 1,6 0,1 2,4 2,5 0,0 4,4 3,4 4,4 1,2 6,7 3,2 -1,7 -4,6 -17,-5 -19,-25 -35,-31 -11,-4 -27,4 -29,22 4,-1 8,0 8,4 4,17 29,7 30,19 0,8 -19,-1 -27,4 -10,7 -11,26 -3,37 9,-9 21,4 30,-2 6,-4 8,-11 15,-14 19,-9 25,9 21,27 10,-5 35,-10 41,-12 10,-3 11,-26 23,-35 -18,0 -40,-8 -45,-25 0,-2 1,-4 1,-6 0,-7 0,-13 -3,-19 0,-1 -1,-1 -1,-2 -1,-1 -1,-2 -2,-3 -2,-2 -4,-4 -7,-5 -1,-1 -2,-1 -3,-2l0 0z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3590 3144c8,15 -31,-9 -45,13 -5,7 -2,23 -2,32 9,-8 18,7 34,-7 2,2 4,3 1,5 -6,6 -9,14 -6,21 2,5 16,14 16,14 1,-10 18,0 21,-19 1,-8 3,-21 15,-16 11,4 13,16 10,28 17,-5 46,-23 64,-10 24,17 63,24 90,33 -18,-13 -24,-46 -21,-68 -44,9 -90,7 -130,-12 -11,-5 -10,-18 -13,-28 -4,-12 -19,-23 -32,-22 -7,1 -25,16 -21,17 -1,0 -1,2 -2,3 3,-2 7,0 9,4 1,2 2,5 4,7 2,2 7,3 8,6z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3695 3383c8,-10 16,-5 19,-5 9,0 8,13 7,21 35,-17 68,-41 109,-32 -24,-25 -15,-78 18,-85 0,0 -22,-4 -24,-5 -29,-19 -65,-36 -84,3 -10,19 8,35 22,51 2,3 -1,5 -5,5 -32,8 -91,13 -93,-35 0,-5 -13,-9 -21,-9 -24,1 -23,31 -21,29 5,-4 21,-3 19,10 -2,11 -24,-6 -33,5 -7,9 -7,25 -2,35 -1,-4 3,-7 6,-7 25,-4 26,4 19,28 -1,2 7,17 12,18 0,0 0,-3 0,-5 1,-1 2,-2 3,-2 3,-1 5,-1 8,-1 1,0 2,-1 3,-2 2,-1 3,-3 5,-5 6,-7 11,-16 20,-17 4,0 7,2 11,4l0 0z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3949 3494c22,-22 39,-52 63,-74 -26,-15 -59,-32 -62,-66 0,-2 4,-5 5,-4 36,21 86,22 112,-15 21,-30 30,-79 72,-82 -14,-27 2,-57 24,-71 -8,-3 -17,-9 -26,-8 -10,2 -15,16 -27,18 19,-63 -18,-152 -87,-150 -13,0 -30,11 -34,25 -4,13 -6,30 4,42 23,27 39,57 32,93 16,-14 31,-35 31,-57 0,-5 10,-2 11,-4 19,-28 -12,-45 -29,-63 -5,-5 7,-12 9,-12 60,18 65,91 40,139 -7,-9 -32,-6 -41,-9 28,14 21,16 20,21 -2,8 -27,5 -32,11 -8,11 -15,15 -15,51 12,-12 27,-31 43,-23 35,18 -2,79 -35,89 -28,9 -55,0 -72,-25 -25,-36 -7,-79 -25,-115 -15,4 -12,23 -19,20 -28,-13 -43,-42 -63,-67 -8,3 -12,11 -20,10 -12,0 -34,-4 -39,2 -6,7 -10,57 39,75 33,12 59,23 80,49 -14,1 -19,-6 -30,-6 -11,0 -18,4 -27,8 -8,4 -18,20 -16,33 8,61 79,62 112,100 6,7 -9,33 -22,44 -11,9 -39,-14 -34,-33 4,-15 -34,-26 -47,-8 5,-1 7,3 6,5 -2,14 15,15 15,22 -1,10 -9,-4 -16,-6 -15,-3 -30,2 -39,14 -3,4 -9,10 -6,16 12,-6 18,12 28,7 7,-3 12,-9 20,-7 3,1 6,5 4,7 -21,14 -38,32 -23,54 0,-5 4,-9 8,-9 6,-2 14,2 20,0 10,-3 5,-13 8,-20 6,-11 26,-29 37,-16 2,2 0,11 -6,11 6,0 12,6 17,5 1,0 3,0 4,-1 1,-1 3,-1 3,-2 2,-3 5,-5 6,-9 4,0 10,1 13,-3 3,-4 6,-7 10,-10l-2 2z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3847 3054c39,-11 83,-31 92,-75 12,7 16,23 16,33 1,55 -23,102 -11,159 1,6 13,27 8,24 -7,-3 -20,-16 -28,-10 -9,7 -9,19 -13,16 -17,-10 -35,-26 -44,-46 -3,-8 -2,-24 -4,-21 -13,14 -40,17 -60,20 5,-4 9,-20 8,-24 -16,-8 -38,19 -38,15 1,-9 2,-11 3,-14 12,-34 34,-69 72,-79z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3528 2148c2,-1 3,-2 2,-2 -14,-20 -31,-18 -39,0 -5,12 24,8 36,2zm28 -21c10,0 18,-7 24,-13 -1,6 8,14 5,20 -4,8 -14,12 -16,19 -4,15 8,29 -1,43 -25,42 -80,28 -117,38 3,-5 -1,-11 -3,-13 17,2 39,-2 43,-14 5,-15 8,-27 1,-40 -12,-21 -37,-11 -56,-4 -3,-13 9,-21 16,-23 20,-5 38,-14 56,-21 16,-6 31,8 47,8z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4255 2140c2,-1 -1,1 0,0 -16,-8 -36,-18 -43,3 -5,15 29,5 43,-3zm25 67c-5,5 -14,5 -20,9 -4,3 -7,5 -11,8 -2,1 -7,4 -9,4 -5,1 -4,1 -9,1 -3,0 -4,1 -7,1 -3,0 -12,0 -15,0 -7,0 -8,0 -15,-1 -4,-1 -8,-2 -12,-3 -3,-1 -5,-1 -8,-1 0,-3 1,-6 3,-8 3,-3 4,0 8,-1 0,0 6,1 6,1 1,0 2,0 3,0 2,0 3,-1 5,-1 2,-1 4,-2 6,-3 1,-1 1,-1 2,-2 1,-1 3,-2 5,-4 4,-5 7,-10 8,-16 0,-5 1,-10 0,-14 0,-1 0,-2 0,-2 0,-4 -2,-7 -4,-11 -11,-21 -37,0 -55,-1 0,-4 0,-11 2,-14 13,-16 32,-8 43,-16 34,-25 44,-7 79,3 11,3 26,-4 36,-8 3,-1 2,15 1,18 -4,11 -16,11 -20,21 -6,15 -7,36 -23,44l2 -2z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M3849 2971c6,2 24,-8 31,-11 2,-1 3,-2 4,-4 0,0 0,-1 0,-1 -5,-1 -10,-2 -15,-2 -4,0 -7,-3 -11,-4 -1,0 -2,0 -3,0 -1,1 -2,2 -3,2 -2,1 -4,1 -6,2 -2,2 -3,3 -4,5 -1,3 0,6 2,8 1,2 3,3 5,3zm47 -28c16,4 33,4 44,-10 5,22 -15,24 -18,35 -6,16 -5,32 -21,40 -31,17 -70,36 -105,39 0,-4 -3,-12 3,-13 12,-1 22,-10 25,-17 4,-10 -1,-35 -10,-40 -11,-6 -20,3 -30,7 -4,-11 13,-29 39,-27 -1,0 13,-15 13,-15 17,-12 36,-6 61,0z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4379 2793c-1,-5 13,-21 13,-32 1,-39 25,-57 25,-99 0,-11 -45,-21 -47,-44 -1,-12 -5,-28 7,-28 15,0 30,-2 40,-12 47,-41 7,-98 2,-148 -1,-11 19,-35 18,-42 -3,-42 -11,-81 4,-123 -12,11 -15,28 -25,37 -5,4 -7,-5 -7,-7 6,-37 21,-69 37,-104 20,-41 10,-75 -27,-87 -34,-11 -67,20 -71,53 -3,30 6,66 13,90 2,7 1,15 2,22 6,-25 7,-50 6,-77 0,-6 5,-15 12,-17 22,-6 34,10 32,22 -5,39 -13,69 -22,108 -11,44 5,88 10,131 -17,-10 -36,-10 -42,16 -4,14 9,29 13,41 0,-17 16,-40 32,-30 19,13 20,74 16,82 -9,18 -24,30 -46,24 -12,-3 -7,-21 -14,-29 -38,-39 -55,-86 -58,-140 -1,-8 4,-29 2,-29 -50,-20 -72,0 -93,29 -4,-9 -22,-21 -24,-19 -9,17 -7,49 7,61 38,33 76,55 98,103 -23,3 -45,10 -54,34 -9,25 -6,60 20,70 22,8 59,-1 67,0 23,3 49,23 45,44 0,1 -10,52 -32,39 -12,-7 -5,-35 -23,-39 -24,-6 -30,28 -27,25 8,-7 13,5 28,13 -51,-11 -35,36 -33,30 3,-7 12,0 30,-1 1,0 -4,4 -4,4 -3,5 -4,7 -6,12 -1,2 -1,5 -1,7 0,3 1,5 2,7 0,0 1,1 1,1 0,0 1,1 1,1 1,0 -1,-4 2,-4 1,0 3,0 3,0 1,0 3,1 5,2 1,0 3,1 4,1 3,0 5,1 8,-1 3,-1 5,-1 8,-3 -3,2 4,-3 3,-3 3,-7 5,-5 6,-6 5,-10 10,-11 22,-10 1,0 3,3 4,4 1,1 3,8 3,5 1,5 4,8 4,12z"/>
      <path className="fil3" style={{fill:'#CE2F4A'}} d="M4011 2323c-3,-2 -4,-7 -6,-9 -1,-1 -1,-2 -2,-2 -1,-1 -2,-3 -2,-4 -2,-7 -1,-16 6,-21 0,0 0,-1 0,-1 0,-1 1,-1 2,-2 0,-1 1,-1 1,-1 0,0 0,0 1,-1 1,-2 1,-5 -1,-6 2,0 4,0 6,1 1,0 2,0 2,0 8,1 18,5 20,13l3 3c8,19 6,67 52,79 24,6 49,14 74,13 -6,24 -6,55 13,73 -28,-15 -47,-12 -71,-35 -11,-10 -36,-24 -48,-22 -5,1 7,-16 -4,-20 -7,-2 -27,-2 -30,3 -16,24 -33,24 -39,29 7,-6 -26,-32 15,-47 4,-1 5,-10 1,-13 -8,-4 -15,2 -23,6 -9,5 -11,-14 -25,-7 -3,-42 35,-46 57,-19 2,2 8,0 5,-3l-5 -9z"/>
      <path className="fil0" style={{fill:'#373435'}} d="M4520 2140c-6,277 -11,542 -93,799l-574 -455 -576 457c-82,-257 -87,-524 -93,-801 -1,-26 -1,-53 -2,-79l1339 0c-1,27 -1,53 -2,80zm-250 1132c-94,145 -239,265 -418,350 -180,-84 -324,-205 -418,-350 -16,-24 -30,-49 -44,-73l464 -368 462 367c-14,25 -29,49 -45,74zm-965 -252l548 -434 546 433c-14,36 -29,71 -46,107l-499 -396 -502 398c-17,-35 -33,-71 -46,-107zm-150 -986l0 14c1,31 1,62 2,93 8,402 16,781 254,1146 98,150 249,276 435,362l6 3 6 -3c186,-87 337,-212 434,-362 238,-364 246,-744 254,-1146 1,-31 1,-62 2,-93l0 -14 -1393 0z"/>
    </g>
  </svg>;

export default SVG;