import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron, Card } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import './Home.scss';
import './css/VerticalTimeline.css';
import './css/VerticalTimelineElement.css';
import Typer from './Typer/Typer';
import RBCLogo from './svg/loaders/RBCLogo';
import TDLogo from './svg/loaders/TDLogo';
import UWaterlooMathLogo from './svg/loaders/UWaterlooMathLogo';

// #15223F

class Home extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row className="show-grid">
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={10}>
              <Jumbotron style={{'marginBottom': '1.5em', 'backgroundColor': 'transparent', 'marginLeft': '1em', 'marginTop': '1em', 'paddingTop': '1em'}}>
                <Typer
                  heading={'James Bury'}
                  dataText={['is a developer.', 'is a designer.', 'is a collaborator.']}
                />
              </Jumbotron>
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>
          <Row className="show-grid">
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={10}>
              <Jumbotron style={{'backgroundColor': 'transparent', 'paddingTop': '1em'}}>
                <h1 style={{'textAlign': 'center', 'marginBottom': '1em'}}>About Me</h1>
                <p>I'm currently studying computer science and finance at the <a href="https://uwaterloo.ca/computing-financial-management/" target="_blank" rel="noopener noreferrer" style={{'fontWeight': '600'}}>University of Waterloo</a>. Now in my 3rd year, I've completed 3 co-ops, along with several courses in computer programming <span role="img" aria-label="laptop">💻</span> and corporate finance <span role="img" aria-label="positive stock graph">📈</span>.</p>
                <p>Over the last two years, I've learned to write powerful code using a wide variety of technologies, both new and old. When I'm not coding <span role="img" aria-label="laptop">💻</span> you can find me working out <span role="img" aria-label="white bicep">💪🏻</span>, going for walks or scooter rides <span role="img" aria-label="scooter">🛴</span> around the city, and playing pool <span role="img" aria-label="8 ball">🎱</span>, ping pong <span role="img" aria-label="ping-pong">🏓</span>, and foosball <span role="img" aria-label="soccer ball">⚽</span>.</p>
                <p>If you're interested in chatting over coffee, message me on LinkedIn or shoot me an email. You never know how much you could learn from a short coffee chat! <span role="img" aria-label="3 coffees">☕ ☕ ☕</span></p>
              </Jumbotron>
              <Jumbotron style={{'backgroundColor': 'transparent', 'paddingBottom': '0', 'paddingTop': '0', 'marginBottom': '2em'}}>
                <h1 id="experience" style={{'margin': '0', 'textAlign': 'center'}}>Work Experience</h1>
              </Jumbotron>
            </Col>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={12}>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid rgb(33, 150, 243)' }}
                  date="September 2019 - December 2019"
                  iconOnClick={() => {
                    window.open("https://www.rbc.com/about-rbc.html", "_blank", "noopener noreferrer");
                  }}
                  icon={<a href="#experience"><RBCLogo /></a>}>
                  <h3 className="vertical-timeline-element-title">Cyber Security Analyst</h3>
                  <h4 className="vertical-timeline-element-subtitle">Toronto, Canada</h4>
                  <p>Software Developer, Threat Hunter, Email Perfectionist</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#258E28', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid #258E28' }}
                  date="January 2019 - April 2019"
                  iconOnClick={() => window.open("https://www.td.com/ca/en/personal-banking/", "_blank", "noopener noreferrer")}
                  icon={<a href="#experience"><TDLogo /></a>}>
                  <h3 className="vertical-timeline-element-title">Red Team Engineer</h3>
                  <h4 className="vertical-timeline-element-subtitle">Toronto, Canada</h4>
                  <p>Hacker, Software Developer, Social Engineer</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#FFC922', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid #FFC922' }}
                  date="May 2018 - August 2018"
                  iconOnClick={() => window.open("https://math.uwaterloo.ca/", "_blank", "noopener noreferrer")}
                  icon={<a href="#experience"><UWaterlooMathLogo /></a>}>
                  <h3 className="vertical-timeline-element-title">Advancement Assistant</h3>
                  <h4 className="vertical-timeline-element-subtitle">Waterloo, Canada</h4>
                  <p>Research Journalist, Audio Transcriber, Javascript Developer</p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}></Col>
            <Col xs={12} md={6}>
              <Jumbotron style={{'backgroundColor': 'transparent', 'paddingBottom': '0', 'paddingTop': '4em', 'marginBottom': '2em'}}>
                <h1 id="education" style={{'margin': '0', 'textAlign': 'center'}}>Education</h1>
              </Jumbotron>
              <Card style={{'backgroundColor': 'transparent'}}>
                <Card.Body>
                  <UWaterlooMathLogo height="100px" viewBox="1700 900 4300 3000" />
                  <Card.Title>Bachelor of Computing & Financial Management</Card.Title>
                  <Card.Subtitle>Expected Graduation: 2022</Card.Subtitle>
                  <Card.Text style={{'fontSize': '16px'}}>Courses include object-oriented programming, corporate finance, functional programming, and financial accounting.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
