import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { withRouter } from "react-router";
import './CustomNavbar.scss'
// import DarkModeSwitch from '../NightLight/DarkModeSwitch.js';

const Header = props => {
  const { location } = props;
  location.state = {
    'bgColour': document.querySelector('body').style.backgroundColor,
    'handler': props.handler,
  };
  console.log(location);

  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="xl">
      <Navbar.Brand href="/">James Bury</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="justify-content-end" activeKey={location.pathname}>
          <Nav.Item>
            <Nav.Link href="/">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://montival.ca" target="_blank" rel="noopener noreferrer">Monti</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/blog">Blog</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/assets/james_bury_resume.pdf">Resume</Nav.Link>
          </Nav.Item>
        </Nav>
        {/*
        <Nav className="ml-auto" bgcolour={location.state.bgColour}>
          <Nav.Item style={{'paddingRight': '2em'}}><DarkModeSwitch handler={location.state.handler} bgColour={location.state.bgColour} /></Nav.Item>
        </Nav>
        */}
        <Nav className="ml-auto">
          <Nav.Item style={{'paddingRight': '2em'}}><a href="https://www.linkedin.com/in/james-bury/" target="_blank" rel="noopener noreferrer"><Image width="30em" src="assets/inlogo.png" /></a></Nav.Item>
          <Nav.Item style={{'paddingRight': '2em'}}><a href="mailto:jabury@uwaterloo.ca?Subject=Hi!" target="_blank" rel="noopener noreferrer"><Image width="30em" src="assets/emaillogo.png" /></a></Nav.Item>
          <Nav.Item style={{'paddingRight': '2em'}}><a href="https://www.github.com/d3an/" target="_blank" rel="noopener noreferrer"><Image width="30em" src="assets/gitlogo.png" /></a></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
const HeaderWithRouter = withRouter(Header);

class CustomNavbar extends React.Component {
  render() {
    return (<HeaderWithRouter handler={this.props.handler} bgColour={this.props.bgColour} />);
  }
}

export default CustomNavbar;
